import React from "react";
import Note from "./Note";

import "./citationNote.scss";
import { NoteWrapperContext } from "./NoteWrapper";

const CitationNote = ({ sourceId, content, children }) => {
  const noteContext = React.useContext(NoteWrapperContext);

  const [num, setNum] = React.useState("?");

  React.useEffect(() => {
    if (sourceId) {
      const index = noteContext.citeArray.indexOf(sourceId);
      if (index >= 0) {
        setNum(index + 1);
      } else {
        noteContext.citeArray.push(sourceId);
        setNum(noteContext.citeArray.length);
      }
    }
  }, [sourceId, noteContext.citeArray]);

  return (
    <Note
      content={
        content && (
          <span className="citation-note__content">
            {content}
            <span className="citation-note__icon">{num}</span>
          </span>
        )
      }
    >
      {children}
    </Note>
  );
};

export default CitationNote;
